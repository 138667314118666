const GTAG_ID = process.env.REACT_APP_GTAG_ID

function gtag() {
  window.dataLayer.push(arguments)
}

function initializeGtag() {
  if (GTAG_ID) {
    window.dataLayer = window.dataLayer || []
    gtag('js', new Date())
    gtag('config', GTAG_ID)
  }
}

export function sendTicketConversion(orderTotal, transactionId) {
  if (!GTAG_ID) return
  gtag('event', 'conversion', {
    send_to: 'AW-799273026/s-cECNnxz9ACEMLgj_0C',
    value: orderTotal,
    currency: 'USD',
    transaction_id: transactionId,
  })
}

initializeGtag()
