import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import * as reduxForm from 'redux-form'
import { flashSuccessMessage } from 'redux-flash'
import { Spinner } from '@launchpadlab/lp-components'
import ReactGA from 'react-ga4'
import { modifyProps } from '@launchpadlab/lp-hoc'
import {
  DonationCheckoutDisplay,
  DonationModal,
  CouponSection,
} from 'components'
import { Waivers, OrderReceipt } from '../components'
import { PaymentForm } from '../../forms'
import { DeliveryOptionsForm } from '../forms'
import * as actions from '../actions'
import * as apiActions from 'main/apiActions'
import * as effects from 'main/effects'
import { selectors } from '../reducer'
import { selectors as userSelectors } from '../../user/reducer'
import * as Types from 'main/types'
import {
  displaySubmitFailure,
  findFirstErrorField,
  isEmpty,
  flashErrorMessage,
} from 'utils'
import { scroller } from 'react-scroll'
import { GpiWrapper, GpiSubmit } from 'components/Gpi'
import { getFormValues } from 'redux-form'
import FieldTripPaymentInfoCard from '../components/FieldTripPaymentInfoCard'
import { TICKETING_CHECKOUT_STEPS } from '../../types'
import SocietyCheckoutLayout from '../../../layout/SocietyCheckoutLayout'
import defaultModalImage from 'images/modal-image.png'

const propTypes = {
  location: PropTypes.object.isRequired,
  customerCountry: PropTypes.string,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitPaymentOrder: PropTypes.func.isRequired,
  submitOrderFailed: PropTypes.func.isRequired,
  submitOrderSucceeded: PropTypes.func.isRequired,
  ticketCart: Types.ticketCart,
  fetchOrCreateTicketCart: PropTypes.func.isRequired,
  setTicketCart: PropTypes.func.isRequired,
  validateOrder: PropTypes.func.isRequired,
  partialPayment: PropTypes.bool.isRequired,
}

const defaultProps = {
  addOns: [],
}

const donationHeader = 'Forest for Us!'
const donationText =
  'Playful. Intelligent. Curious. Orangutans are amazing primates and you can help support them by making a gift toward their new and improved home at Hubbard Orangutan Forest. Double the size, more opportunities for play and enrichment, and new ways for people and orangutans to interact. This new habitat will delight both the orangutans and guests alike! I would like to support the campaign by making a donation of'

function PaymentDetails({
  location,
  customerCountry,
  isSubmitting,
  flashSuccessMessage,
  flashErrorMessage,
  submitForm,
  changeForm,
  submitPaymentOrder,
  submitOrderFailed,
  submitOrderSucceeded,
  ticketCart,
  fetchOrCreateTicketCart,
  setTicketCart,
  validateOrder,
  partialPayment,
}) {
  const [clickedWaivers, setClickedWaivers] = useState([])
  const cartToken = location.query.cartToken
  const backUrl = `/ticketing/cart${location.search}`

  // If we have a large group activity, fetch the mailing fee from the backend and display the
  // delivery options form
  const largeGroupLineItem =
    ticketCart && !!ticketCart.lineItems.find((item) => item.largeGroup)
  const [mailingFee, setMailingFee] = useState(null)
  useEffect(() => {
    if (!largeGroupLineItem) return

    effects
      .getTicketMailDeliveryFees(cartToken)
      .then((response) => {
        setMailingFee(response.price)
      })
      .catch(() => {
        setMailingFee(false)
      })
  }, [largeGroupLineItem])

  // this function will update the cart when the delivery option gets selected,
  // so the order receipt will reflect the delivery fee
  function updateMailingFee({ value }, callback) {
    const mailFeeOptions = {
      Digital: 0,
      Mail: mailingFee,
      'Will Call': 0,
    }
    effects
      .updateTicketCart(ticketCart.token, {
        mailingFee: mailFeeOptions[value],
      })
      .then((resp) => {
        setTicketCart({ success: resp })
        callback(value)
      })
      .catch(() => {
        flashErrorMessage(
          'Could not complete this action. Please try again later'
        )
      })
  }

  useEffect(() => {
    cartToken && fetchOrCreateTicketCart(cartToken)
  }, [cartToken, fetchOrCreateTicketCart])

  if (!ticketCart) return <Spinner />
  const onGiftCardUpdate = (giftCardNumber = null) =>
    effects.updateTicketCart(ticketCart.token, {
      gift_card_number: giftCardNumber,
    })
  const onCouponUpdate = ({ couponCode, couponType }) =>
    effects.updateTicketCart(ticketCart.token, {
      coupon: couponCode,
      coupon_type: couponType,
    })
  const onDonationUpdate = (donationAmount = null) =>
    effects.updateTicketCart(ticketCart.token, {
      donation_amount: donationAmount,
    })
  const handleDonationUpdate = (opt) => {
    var totalBeforeDonation = ticketCart.total
    onDonationUpdate(opt.level)
      .then((resp) => {
        setTicketCart({ success: resp })
        flashSuccessMessage('Donation added')

        ReactGA.event('add_to_cart', {
          currency: 'USD',
          value: opt.level,
          items: [
            {
              price: opt.level,
              quantity: 1,
              item_category: 'Activity',
              item_category2: 'donation',
            },
          ],
        })

        // if cart total was originally 0 and a donation is being made,
        // we need to refresh the page, so payment form can be reloaded.
        if (totalBeforeDonation === 0) {
          window.location.reload()
        }
      })
      .catch(() =>
        flashErrorMessage('Something went wrong. Please try again later.')
      )
  }

  const handleClickedWaiver = (id) => {
    // if eventId of waiver doesn't already exist in clickedWaivers, add it
    if (!(clickedWaivers.indexOf(id) >= 0)) {
      const newArr = [...clickedWaivers, id]
      setClickedWaivers(newArr)
    }
  }

  const initialDeliveryOptions = (function () {
    if (!largeGroupLineItem) {
      return {}
    } else if (ticketCart.mailingFee) {
      return { deliveryOption: 'Mail' }
    } else {
      return { deliveryOption: 'Digital' }
    }
  })()

  const isFieldTripBooking = Boolean(ticketCart.schoolName)

  const paymentFormInitialValues = {
    customerInfo: { country: 'US' },

    partialPayment: isFieldTripBooking ? 'true' : 'false',
  }

  const ticketLineItemsWithWaivers = ticketCart.lineItems.filter(
    (li) => !!li.waiverUrl
  )
  const showWaivers = ticketLineItemsWithWaivers.length > 0

  const waiversNeedAction =
    showWaivers &&
    ticketLineItemsWithWaivers.some(
      (waiver) => !clickedWaivers.includes(waiver.id)
    )

  const handleSubmit = ({
    cardToken,
    expirationDate,
    lastFourDigits,
    cardType,
    // maskedCardNumber, // not currently used but could be sent to server
  }) => {
    // https://redux-form.com/8.3.0/docs/api/props.md/#-code-change-field-string-value-any-function-code-

    // update the form's card token with the temporary payment token from GPI
    // and add any other payment-related fields that our server needs to know about
    changeForm('cart-payment', 'billingInfo.cardToken', cardToken)
    changeForm('cart-payment', 'billingInfo.expirationDate', expirationDate)
    changeForm('cart-payment', 'billingInfo.lastFourDigits', lastFourDigits)
    changeForm('cart-payment', 'billingInfo.cardType', cardType)
    // submit the form, which triggers other form validations and then ultimately submits to our server
    submitForm('cart-payment')
  }

  const zeroDeposit = partialPayment === 'true' && ticketCart.depositTotal <= 0

  const skipPayment = ticketCart.total <= 0 || zeroDeposit

  const allowChoosingPaymentMode =
    !isFieldTripBooking && ticketCart.allowPartialPayment

  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false)

  useEffect(() => {
    if (ticketCart.donationAmount === 0) {
      const timeout = setTimeout(() => setIsDonationModalOpen(true), 400)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [])

  return (
    <SocietyCheckoutLayout
      progress={{ steps: TICKETING_CHECKOUT_STEPS, currentStep: 4 }}
      backUrl={backUrl}
      title="Payment Info"
      summary={<OrderReceipt />}
      ContinueButton={skipPayment ? null : <GpiSubmit />}
      onContinue={() => submitForm('cart-payment')}
      continueDisabled={isSubmitting || waiversNeedAction}
    >
      <GpiWrapper
        onTokenSuccess={handleSubmit}
        gpiFormHasBeenRendered={!skipPayment}
        isDisabled={isSubmitting || skipPayment}
      >
        {showWaivers && (
          <Waivers
            clickedWaivers={clickedWaivers}
            onWaiverClicked={handleClickedWaiver}
            ticketLineItems={ticketLineItemsWithWaivers}
          />
        )}
        {isFieldTripBooking && <FieldTripPaymentInfoCard />}
        <PaymentForm
          isUsCustomer={customerCountry === 'US'}
          onSubmit={submitPaymentOrder}
          onSubmitSuccess={submitOrderSucceeded}
          onSubmitFail={submitOrderFailed}
          initialValues={paymentFormInitialValues}
          appliedGiftCardAmount={ticketCart.totalGiftCard}
          remainingGiftCardBalance={ticketCart.giftCardBalance}
          onValidateOrder={validateOrder}
          onGiftCardApply={onGiftCardUpdate}
          onGiftCardApplySuccess={(resp) => {
            setTicketCart({ success: resp })
            flashSuccessMessage('Gift card successfully applied!')
          }}
          onGiftCardApplyFail={(err) => {
            const msg =
              (err && err.errors && err.errors.message) ||
              'Something went wrong. Please try again in a moment.'
            flashErrorMessage(msg)
          }}
          onGiftCardRemove={() => onGiftCardUpdate(null)}
          onGiftCardRemoveSuccess={(resp) => {
            setTicketCart({ success: resp })
            flashSuccessMessage('Gift card successfully removed')
          }}
          onGiftCardRemoveFail={(err) => {
            const msg =
              (err && err.errors && err.errors.message) ||
              'Something went wrong. Please try again in a moment.'
            flashErrorMessage(msg)
          }}
          zeroDeposit={skipPayment}
          extendedCustomerInfo={true}
          allowChoosingPaymentMode={allowChoosingPaymentMode}
          depositTotal={ticketCart.depositTotal}
          orderTotal={ticketCart.total}
          enableReinitialize={true}
          keepDirtyOnReinitialize={true}
        />
        <div className="member-form">
          <CouponSection
            coupon={ticketCart.coupon}
            onSubmit={(params) =>
              effects.validateTicketingCouponCode({
                couponCode: params.coupon || null,
                cartToken: ticketCart.cartToken,
              })
            }
            onSubmitSuccess={(res) => {
              onCouponUpdate({
                couponCode: res.couponCode,
                couponType: res.couponType,
              })
                .then((resp) => {
                  setTicketCart({ success: resp })
                  flashSuccessMessage('Coupon Code applied!')
                })
                .catch(() => {
                  flashErrorMessage(
                    'Something went wrong. Please try again later.'
                  )
                })
            }}
            onSubmitFail={(error) => {
              flashErrorMessage(error.message)
            }}
            onRemove={() =>
              onCouponUpdate({
                couponCode: null,
                couponType: null,
              })
            }
            onRemoveSuccess={(resp) => {
              setTicketCart({ success: resp })
              flashSuccessMessage('Coupon Code removed')
            }}
            onRemoveFail={() => {
              flashErrorMessage('Something went wrong. Please try again later.')
            }}
          />
        </div>
        {ticketCart.schoolName === null && !isDonationModalOpen && (
          <DonationCheckoutDisplay
            donation={{ amount: ticketCart.donationAmount }}
            header={donationHeader}
            text={donationText}
            addDonation={handleDonationUpdate}
            removeDonation={() => {
              onDonationUpdate(null)
                .then((resp) => {
                  setTicketCart({ success: resp })
                  flashSuccessMessage('Donation removed')
                })
                .catch(() =>
                  flashErrorMessage(
                    'Something went wrong. Please try again later.'
                  )
                )
            }}
          />
        )}
        {largeGroupLineItem && (
          <DeliveryOptionsForm
            largeGroupLineItem={largeGroupLineItem}
            mailingFee={mailingFee}
            initialValues={initialDeliveryOptions}
            updateMailingFee={updateMailingFee}
          />
        )}
        {ticketCart.schoolName === null && (
          <DonationModal
            isOpen={isDonationModalOpen}
            onClose={() => setIsDonationModalOpen(false)}
            header={donationHeader}
            text={donationText}
            onConfirm={handleDonationUpdate}
            amount={ticketCart.donationAmount}
            cover={defaultModalImage}
          />
        )}
      </GpiWrapper>
    </SocietyCheckoutLayout>
  )
}

PaymentDetails.propTypes = propTypes
PaymentDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  const ticketCart = selectors.ticketCart(state)
  return {
    customerCountry: reduxForm.formValueSelector('cart-payment')(
      state,
      'customerInfo.country'
    ),
    deliveryOptionPayload: reduxForm.formValueSelector('delivery-option')(
      state,
      'deliveryOption'
    ),
    isSubmitting: reduxForm.isSubmitting('cart-payment')(state),
    ticketCart: ticketCart,
    isVerifiedMilitary: userSelectors.isVerifiedMilitary(state),
    partialPayment:
      (getFormValues('cart-payment')(state) || {}).partialPayment || 'false',
  }
}

const mapDispatchToProps = {
  flashSuccessMessage,
  flashErrorMessage,
  push,
  submitForm: reduxForm.submit,
  changeForm: reduxForm.change,
  fetchOrCreateTicketCart: apiActions.fetchOrCreateTicketCart,
  setTicketCart: actions.setTicketCart,
}

function modify({ push, ticketCart, deliveryOptionPayload }) {
  return {
    validateOrder: () => effects.validateTicketAvailability(ticketCart.token),
    submitPaymentOrder: (formResponse) => {
      return new Promise((resolve, reject) => {
        const topLevelTimedTicketingLineItems = ticketCart.lineItems.filter(
          (lineItem) => lineItem.type == 'TimedActivity'
        )
        const addOnTimedTicketingLineItems = ticketCart.lineItems.reduce(
          (previousArray, lineItem) => {
            let timedAddOns = lineItem.addOnLineItems.filter(
              (addOnLineItem) => addOnLineItem.type == 'TimedActivity'
            )
            return previousArray.concat(timedAddOns)
          },
          []
        )
        const allLineItemsToPlaceOnHold =
          topLevelTimedTicketingLineItems.concat(addOnTimedTicketingLineItems)

        // hold API calls must be initiated from within Promise.all
        // or else they will resolve this promise prematurely and make the submit button re-clickable
        Promise.all(
          allLineItemsToPlaceOnHold.map((lineItem) =>
            effects.holdTickets(ticketCart.token, lineItem.id)
          )
        )
          .then(() =>
            effects
              .createTicketOrder({
                formCustomerInfo: formResponse.customerInfo,
                formBillingInfo: formResponse.billingInfo,
                cartToken: ticketCart.token,
                deliveryOption: deliveryOptionPayload,
                partialPayment: formResponse.partialPayment,
              })
              .then(resolve)
              .catch(reject)
          )
          .catch(reject)
      })
    },
    submitOrderSucceeded: (response) => {
      ReactGA.event('purchase', {
        currency: 'USD',
        transaction_id: response.data.attributes.transactionId,
        value: ticketCart?.total,
        items: ticketCart?.lineItems?.map((cartItem) => ({
          item_id: cartItem.productId,
          item_name: cartItem.displayName,
          item_category: cartItem.productType,
          price: Number(
            cartItem.lineItemPrices
              ?.reduce((total, item) => total + item.price, 0)
              .toFixed(2)
          ),
          quantity: Number(
            cartItem.lineItemPrices?.reduce(
              (total, item) => total + item.quantity,
              0
            )
          ),
        })),
      })

      push(
        `/ticketing/confirmation?orderToken=${response.data.attributes.token}`
      )
    },
    submitOrderFailed: (errors, dispatch, submitError, { syncErrors }) => {
      displaySubmitFailure(errors, dispatch, submitError)
      // Check for sync errors here as onSubmitFail is overridden in props
      if (!isEmpty(syncErrors)) {
        const firstErrorField = findFirstErrorField(syncErrors)
        if (!firstErrorField) return
        return scroller.scrollTo(firstErrorField, { smooth: true })
      }
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify)
)(PaymentDetails)
